<template>
  <div>
      <Table
        border
         :loading="tableLoading"
         ref="selection"
         stripe
         :height="200"
         :columns="talbeColumns"
         :data="tableData"
         style="margin-top:20px;"
         :show-summary="true"
          :summary-method="handleSummary"
    ></Table>
     <VeLine style="margin-top:60px;" v-if="showLine" :data="userStatData"></VeLine>
  </div>
</template>

<script>
import VeLine from "v-charts/lib/line.common";
export default {
    components:{
        VeLine
    },
    props:{
        isShow:{
            type:Number,
            default:()=>{
                return ''
            }
        },
        
    },
    data(){
        return{
            tableLoading:false,
            talbeColumns:[
                {
                    title: "发送时间",
                    minWidth: 100,
                    key:'dateDay',
                    align: "center"
                },
                {
                    title: "发送总数(条)",
                    key: "userCount",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送成功(条)",
                    key: "successCount",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送失败(条)",
                    key: "errorCount",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送成功率",
                    key: "percentage",
                    minWidth: 100,
                    align: "center",
                },
            ],
            tableData:[],
            redData:{},
            // 社区用户结束
            chartSettings: {
                area: true,
                itemStyle: {
                //面积图颜色设置
                color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                    {
                        offset: 0,
                        color: "rgba(254,106,172,0.6)" // 0% 处的颜色
                    },
                    {
                        offset: 1,
                        color: "rgba(255,139,119,0.1)" // 100% 处的颜色
                    }
                    ],
                    globalCoord: false // 缺省为 false
                }
                }
            },
             // 社区用户开始
            userStatData: {
                columns: ["时间", "发送总数","发送成功数","发送失败数"],
                rows: []
            },
            showLine:true
                    
        }
    },
    methods:{
        handleSummary ({ columns, data }) {
                const sums = {};
                columns.forEach((column, index) => {
                    const key = column.key;
                    if (index === 0) {
                        sums[key] = {
                            key,
                            value: '小结'
                        };
                        return;
                    }
                    const values = data.map(item => Number(item[key]));
                    if (!values.every(value => isNaN(value))) {
                        sums.userCount = {
                            key,
                            value: this.redData.userCount
                        };
                        sums.successCount = {
                            key,
                            value: this.redData.successCount
                        };
                        sums.errorCount = {
                            key,
                            value: this.redData.errorCount
                        };
                    } else {
                        sums.percentage = {
                            key,
                            value: this.redData.percentage
                        };
                    }
                });

                return sums;
            },
        getList(){
            this.tableLoading = true
            this.$get('/syaa/pc/sy/sms/selectStatSms',{
                startDate:'',
                endDate:'',
                dateType:'',
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                oemCode:parent.vue.oemInfo.oemCode,
            }).then(res=>{
                 this.tableLoading = false
                 if(res.code == 200){
                     this.tableData = res.data.dataList
                     this.redData= res.data
                     if(this.tableData.length != 0){
                         this.showLine = false
                         this.$nextTick(()=>{
                             this.showLine = true
                             this.userStatData.rows = []
                             this.tableData.map(item=>{
                                this.userStatData.rows.push({时间: item.dateDay,发送总数:item.userCount,发送成功数:item.successCount,发送失败数:item.errorCount})
                            })
                         })
                     }
                 }else{
                     this.$Message.error({
                         content:res.desc,
                         background:true
                     })
                     return
                 }
            })
        }
    },
    watch:{
        isShow:{
            handler(val){
                if(val){
                    this.getList()
                }
            }
        }
    },
    

}
</script>

<style>

</style>