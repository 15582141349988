<template>
  <LiefengContent :isBack='true' @backClick="backClick">
    <template v-slot:title> 短信记录 
        <!-- <Button type="error" @click="reBack">返回</Button> -->
        <!-- <Button type="error" v-if="$route.query.isLineHome " @click="$router.push(`/linehomeindex?isLineHome=${$route.query.isLineHome}&page=${$route.query.page}`)">返回</Button>
        <Button type="error" v-if="$route.query.name == 'basebookindex'" @click="$router.push(`/basebookindex?isLineHome=${$route.query.isLineHome}`)">返回</Button>
        <Button type="error" v-if="$route.query.name == 'mobilizationindex'" @click="$router.push(`/mobilizationindex?isLineHome=${$route.query.isLineHome}`)">返回</Button> -->
    </template>
    <template v-slot:toolsbarRight>
        <Form  :label-colon="true" :inline="true" class="search">
        <FormItem>
        <Input
            :maxlength="100"
            style="margin-right: 10px; width: 200px"
            placeholder="短信内容"
            v-model.trim="searchFrom.content"
        />
        </FormItem>
        <Button style="margin: 0 4px" @click="getList" type="primary" icon="ios-search">查询</Button>
        <Button style="margin: 0 4px" type="success" @click="reset" icon="ios-refresh">重置</Button>
        <Button style="margin: 0 4px" type="primary" @click="moOpenMessage" v-if="$route.query.name == 'mobilizationindex'">发短信</Button>
        <Button style="margin: 0 4px" type="primary" @click="openMessage" v-if="$route.query.name == 'basebookindex'">发短信</Button>
        <Button style="margin: 0 4px" type="warning" @click="$router.push('/emailgroup?name=linehomeindex')" v-if="$route.query.name == 'linehomeindex'">发消息</Button>
        <Button style="margin: 0 4px" type="primary" @click="openRecord">短信统计</Button>
        </Form>
    </template>
    <template v-slot:contentArea>
        <LiefengTable
            :talbeColumns="talbeColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :pagesizeOpts="[20, 30, 50, 100]"
            :curPage="page"
            :total="total"
            :page-size="pageSize"
            @hadlePageSize="hadlePageSize"
        ></LiefengTable>
        <!--短信发送详情-->
      <LiefengModal :value="messageStatus"  @input="messageInput" title="短信发送详情" :fullscreen="true" width="800px" height="540px">
          <template v-slot:contentarea>
              <Table :isShow="isShow" :infoId="infoId"></Table>
          </template>
          <template v-slot:toolsbar>
            <Button style="margin: 0 5px 0 13px" type="primary" @click="messageInput(false)">确定</Button>
          </template>
      </LiefengModal>
      <!--发短信-->
      <LiefengModal v-if="$route.query.name == 'basebookindex'" :value="messageStatusOther"  @input="messageInputOther" title="发短信" :fullscreen="false" width="800px" height="540px">
          <template v-slot:contentarea>
              <Message  @SmsUser="SmsUser" ref="message" @getCommunityCode='getCommunityCode' :isShow="showNumber"></Message>
          </template>
          <template v-slot:toolsbar>
             <Button type="info" @click="messageBack" style="margin-right: 10px" >取消</Button>
            <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="messageSend">确定发送</Button>
          </template>
      </LiefengModal>

      <!-- 动员短信 -->
        <LiefengModal v-if="$route.query.name == 'mobilizationindex'" :value="moMessageStatus"  @input="moMessageInput" title="发短信" :fullscreen="false" width="800px" height="540px">
          <template v-slot:contentarea>
              <Momessage ref="moMessage" :isShow="moShowNumber"></Momessage>
          </template>
          <template v-slot:toolsbar>
             <Button type="info" @click="moMessageBack" style="margin-right: 10px" >取消</Button>
            <Button style="margin: 0 5px 0 13px" :disabled="moSendDidsabled" type="primary" @click="moMessageSend">确定发送</Button>
          </template>
      </LiefengModal>
      <!--短信统计-->
      <LiefengModal :value="messageStatistics"  @input="messageStatisticsInput" title="短信统计" :fullscreen="true" >
          <template v-slot:contentarea>
            <Statistics :isShow="showStatistics"></Statistics>
          </template>
          <template v-slot:toolsbar>
            <Button style="margin: 0 5px 0 13px" type="primary" @click="messageStatisticsInput(false)">确定</Button>
          </template>
      </LiefengModal>
      <!-- 短信开通提醒 -->
      <LiefengModal 
        title='短信开通提醒'
        :value="openTips"
        @input="changeOpenTips"
        width="500px"
        height="200px"
        >
        <template v-slot:contentarea>
          <p style="fontSize:16px">{{orgName}}还未开通短信服务，通过随约系统发送短信，会产生短信费最高0.07元/条，是否提交开通此项服务的申请？如有疑问请联系社区实施专管员。</p>
        </template>
        <template v-slot:toolsbar>
             <Button type="primary" style="marginRight:10px" :loading='opLoading' @click="goOpen">我要申请开通</Button>
            <Button @click="notOpen">暂不开通</Button>
          </template>
      </LiefengModal>
    </template>
    
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import Table from './childrens/table.vue'
import Message from './childrens/message.vue'
import Momessage from './childrens/moMessage.vue'
import Statistics from './childrens/statistics.vue'
export default {
    components:{LiefengContent,LiefengTable,LiefengModal,Table,Message,Statistics,Momessage},
    data(){
        return{
            communityCode:'',
            orgName:'',
            openTips:false,
            talbeColumns:[            
              {
                    title: "短信内容",
                    key: "smsMsg",
                    minWidth: 400,
                    align: "center",
                },
                {
                    title: "发送总数(条)",
                    key: "userCount",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送成功(条)",
                    key: "successCount",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送失败(条)",
                    key: "errorCount",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送人",
                    key: "sender",
                    minWidth: 100,
                    align: "center",
                },
                {
                    title: "发送时间",
                    minWidth: 100,
                    align: "center",
                    render:(h,params)=>{
                        return h('div',{},params.row.sendTime ? this.$core.formatDate(
                                        new Date(params.row.sendTime),
                                        "yyyy-MM-dd hh:mm:ss"
                                      ):'')
                    }
                },
                 {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 80,
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small",
                            },
                            on: {
                                click: () => {
                                    this.messageStatus = true
                                    this.infoId = params.row.infoId
                                    ++this.isShow
                                },
                            },
                            },
                            "详情"
                        ),
                        ]);
                    },
                    },
            ],
            tableData:[],
            loading:false,
            page:1,
            total:0,
            pageSize:20,
            searchFrom:{
                content:''
            },
            messageStatus:false,
            isShow:0,
            infoId:'',
            // 发消息
            messageStatusOther:false,
            showNumber:0,
            // 短信统计
            messageStatistics:false,
            showStatistics:0,
            sendDisabled:false,
            opLoading:false,


            //社区动员发消息
            moMessageStatus:false,
            moShowNumber:0,
            sendDidsabled:false,

        }
    },
    created(){
        this.getList()
        this.orgName = parent.vue.loginInfo.userinfo.orgName
    },
    methods:{
        backClick(){
            if(this.$route.query.name == 'mobilizationindex'){
                this.$router.push(`/mobilizationindex?isLineHome=${this.$route.query.isLineHome}`)
            }else if(this.$route.query.name == 'basebookindex'){
                this.$router.push(`/basebookindex?isLineHome=${this.$route.query.isLineHome}`)
            }else {
                this.$router.push(`/linehomeindex?isLineHome=${this.$route.query.isLineHome}&page=${this.$route.query.page}`)
            }
        },
        getCommunityCode(code){
            this.communityCode = code
        },
        // 开通短信提示
        changeOpenTips(status){
            this.openTips = status
        },
        goOpen(){
            this.opLoading = true
            this.$post('/syaa/pc/sy/smsChannel/submitChannel',{
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                channelId:'1'
            },
            { "Content-Type": "application/json" }).then( res => {
                if(res.code == 200){
                    this.$Message.success({
                        content:res.desc,
                        background:true
                    })
                }
                if(res.code == 500){
                   this.$Message.warning({
                        content:res.desc,
                        background:true
                    }) 
                }
            })
            this.opLoading = false
            this.changeOpenTips(false)
        },
        notOpen(){
            this.changeOpenTips(false)
        },
        SmsUser(status){
            this.sendDisabled = status
        },
        messageStatisticsInput(status){
            this.messageStatistics = status
        },
         // 短信统计
         openRecord(){
              this.messageStatistics = true
              ++this.showStatistics
         },
        // 点击发消息按钮
        openMessage(){
            this.$get('/syaa/pc/sy/sms/checkSmsChannel',{
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
            }).then( res => {
                if(res.code == 200){
                    this.messageStatusOther = true
                    ++this.showNumber
                    this.sendDisabled = false
                     this.$refs.message.$refs.selectRef.sortSelect = []
                    this.$refs.message.$refs.selectRef.tableData.map(item =>{
                        item["_checked"] = null 
                    })
                    this.$refs.message.$refs.selectRef.showTable = false
                    this.$nextTick(()=>{
                        this.$refs.message.$refs.selectRef.showTable = true
                    })

                }
                if(res.code == 500){
                    this.changeOpenTips(true)
                }
            })

            
        },
        // 发消息
        messageInputOther(status){
             this.messageStatusOther = status
        },
        // 弹窗返回按钮
        messageBack(){
        this.messageStatusOther = false
        },
        // 弹窗确认按钮
        messageSend(){
            console.log(this.communityCode)
            console.log('点击了短信的',this.$refs.message.selectList);
            console.log('看看有没有内容',this.$refs.message.formData.content);
            if(this.$refs.message.selectList.length == 0){
                this.$Message.warning({
                content:'请选择接收人',
                background:true
                })
                return
            }
            if(!this.$refs.message.formData.content || this.$refs.message.formData.content == ''){
                this.$Message.warning({
                content:'请填写短信内容',
                background:true
                })
                return
            }
            if(this.$refs.message.isType == ''){
                let data = []
                this.$refs.message.selectList.map(item=>{
                    data.push(item.id)
                })
                this.sendRow(data)
            }else if(this.$refs.message.isType == 'all'){
                this.sendAll()
            }
            
        
        },
        sendRow(data){
            this.$post('/syaa/pc/sy/user/userOriginal/sendUserOriginalSms',{
                staffOrgCode:parent.vue.loginInfo.userinfo.orgCode,
                staffName:parent.vue.loginInfo.userinfo.realName,
                businessCode:'2',
                oemCode:parent.vue.oemInfo.oemCode,
                smsMsg:this.$refs.message.formData.content,
                ids:data.join(',')
            }).then(res=>{
                if(res.code == 200){
                this.messageStatusOther = false
                this.$Message.success({
                    content:'短信发送成功',
                    background:true
                })
                this.$refs.message.$refs.selectRef.sortSelect = []
                this.$refs.message.$refs.selectRef.tableData.map(item =>{
                    item["_checked"] = null 
                })
                this.$refs.message.$refs.selectRef.showTable = false
                this.$nextTick(()=>{
                    this.$refs.message.$refs.selectRef.showTable = true
                })
                this.page = 1
                this.pageSize = 20
                this.getList()
                }else{
                this.$Message.error({
                    content:res.desc,
                    background:true
                })
                return
                }
            })
        },
        // 发送全部的接口
        sendAll(){
            this.$post('/syaa/pc/sy/user/userOriginal/sendAllUserOriginalSms',{
                staffOrgCode:parent.vue.loginInfo.userinfo.orgCode,
                staffName:parent.vue.loginInfo.userinfo.realName,
                businessCode:'2',
                oemCode:parent.vue.oemInfo.oemCode,
                smsMsg:this.$refs.message.formData.content,
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode:this.communityCode
            },{"Context-Type":'Application/json'}).then(res=>{
                if(res.code == 200){
                this.messageStatusOther = false
                this.$Message.success({
                    content:'短信发送成功',
                    background:true
                })
                this.$refs.message.$refs.selectRef.sortSelect = []
                this.$refs.message.$refs.selectRef.tableData.map(item =>{
                    item["_checked"] = null 
                })
                this.$refs.message.$refs.selectRef.showTable = false
                this.$nextTick(()=>{
                    this.$refs.message.$refs.selectRef.showTable = true
                })
                this.page = 1
                this.pageSize = 20
                this.getList()
                }else{
                this.$Message.error({
                    content:res.desc,
                    background:true
                })
                return
                }
            })
        },
        reBack(){
            this.$router.push(`/${this.$route.query.name}`)
        },
        // 方法部分
        messageInput(status){
            this.messageStatus = status
        },
        // 点击发消息按钮
        moOpenMessage(){
        this.$get('/syaa/pc/sy/sms/checkSmsChannel',
            {
            orgCode:parent.vue.loginInfo.userinfo.orgCode,
            }).then( res => {
                if(res.code == 200){
                this.moMessageStatus = true
                // ++this.moShowNumber
                this.moSendDidsabled = false
                }
                if(res.code == 500){
                this.changeOpenTips(true)
                }
            })
        },
        moMessageInput(status){
            this.moMessageStatus = status
        },
        moMessageBack(){
            this.moMessageStatus = false
        },
        // 弹窗确认按钮
        moMessageSend(){
        if(this.$refs.moMessage.selectList.length == 0){
            this.$Message.warning({
            content:'请选择接收人',
            background:true
            })
            return
        }
        if(this.$refs.moMessage.formData.content == ''){
            this.$Message.warning({
            content:'请填写短信内容',
            background:true
            })
            return
        }
        let data = []
        this.$refs.moMessage.selectList.map(item=>{
            data.push(item.id)
        })
        this.moSendRow(data)
        
        },
        moSendRow(data){
            this.moSendDidsabled = true
            this.$post('/syaa/pc/sy/user/communityStructure/sendMemberSms',{
                staffOrgCode:parent.vue.loginInfo.userinfo.orgCode,
                staffName:parent.vue.loginInfo.userinfo.realName,
                businessCode:'3',
                oemCode:parent.vue.oemInfo.oemCode,
                smsMsg:this.$refs.moMessage.formData.content,
                ids:data.join(',')
            }).then(res=>{
                this.moSendDidsabled = false
                if(res.code == 200){
                this.messageStatus = false
                this.$Message.success({
                    content:'短信发送成功',
                    background:true
                })
                
                }else{
                this.$Message.error({
                    content:res.desc,
                    background:true
                })
                return
                }
            })
            },
        // messageSend(){
        //      this.messageStatus = false
        // },
        // 分页方法
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        reset(){
            this.searchFrom.content = ''
            this.getList()
        },
        // 接口部分
        getList(){
            this.loading = true
            this.$get('/syaa/pc/sy/sms/selectInfoPage',{
                orgCode:parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                keyword:this.searchFrom.content,
                page:this.page,
                pageSize:this.pageSize
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.total = res.maxCount

                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        }
    }
}
</script>

<style>

</style>